export const backendUrl="https://minicardiacsurgeryadmin.deventa.io"
export const backendRelativePath=""
export let backendURLWithRelativePath=`${backendUrl}${backendRelativePath}`
export let apiURL=`${backendURLWithRelativePath}/api/v2`
export const chatURL="https://minicardiacsurgerychat.deventa.io"

// Functionalities
export const disableChat = true
export const disableFooter = true

// These consts, if available, do manage public sites
// They will access with a token
// and have all the same user (the one, on the backend, which is associated to this token)
// If publicAccess is true, then the other constants become relevant, otherwise, not
export const publicAccess = true
export const accessToken = "e5bff11a-2772-41d0-b825-44846afe6312"

export const feName = "Mini Cardiac Surgery"
// Mux Com Settings
export const muxMonitorEnvKey = "imo6gmt7pd6sblhhnnv3ktal8"

export const gtagKey = ""
