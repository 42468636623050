<template>
	<div class="bottom-content-row" v-if="data && data.length > 0">
		<div class="card" v-for="upload in data" :key="upload.id">
			<!-- loadOnDemandVideo(data_id, playback_url, languages) //-->
			<!-- , languages[upload.container.id] //-->
			<!-- this.embeddableUrl = `${this.backendURLWithRelativePath}/embeddable/${this.currentController == "uploads" ? "vod" : "live"}/${this.currentId}` -->
			<div v-if="'container' in upload && 'id' in upload.container" v-on:click="$emit('send-to-player', upload.id, `${backendURLWithRelativePath}/embeddable/vod/${upload.id}`)" style="cursor: pointer;">
				<img class="playlist-image" :src="upload.preview_url" />
			</div>
			<div v-else v-on:click="$emit('send-to-player', upload.id, `${backendURLWithRelativePath}/embeddable/vod/${upload.id}`)" style="cursor: pointer;">
				<img class="playlist-image" :src="upload.preview_url" />
			</div>
			<div class="upload-controls">
				<strong>{{ upload.filetype }}</strong>
				<br />
				<small>{{ 'container' in upload && 'name' in upload.container ? upload.container.name : upload.safe_title }}</small>
				<br />
				<small class="uploaded-element-languages" v-if="upload.container && 'name' in upload.container">
					<!-- , languages[upload.container.id] -->
					<span v-for="lang in languages[upload.container.id]" :key="lang.id" class="send-to-player pointer lang" v-on:click="$emit('send-to-player', upload.id, `${backendURLWithRelativePath}/embeddable/vod/${lang.id}`)">
						<ion-button class="lang-button" size="small" shape="round">{{ lang.language_code }}</ion-button>
					</span>
				</small>
			</div>
		</div>
	</div>
	<div class="bottom-content-row" v-else>Nessun video presente</div>
</template>

<style scoped>
	.bottom-content-row {
		padding: 1em;
		height: 100%;
		overflow: auto;
		display: flex;
		justify-content: space-around;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: flex-start;
	}

	.card {
		display: inline-block;
		margin-right: .5em;
		margin-left: .5em;
		font-size: small;
		text-align: left;
		vertical-align: text-top;
		margin-bottom: 1.5em;
	}

	.card div img {
		height: min(100px, 20vw);
	}

	.playlist-image {
		background-image: url("~@/assets/empty_image.png");
		background-size: cover;
		border-radius: .7em;
	}

	.upload-controls {
		line-height: 77%;
	}

	small {
		font-size: xx-small;
	}

	.lang {
		font-weight: bold;
		color: var(--ion-color-primary);
	}

	.lang-button {
		width: 22px;
		height: 20px;
	}

	.uploaded-element {
		display: inline-block;
		vertical-align: top;
	}
</style>

<script>
	import {
		IonButton,
	} from "@ionic/vue";
	import {
		defineComponent
	} from "vue";

	import {
		backendURLWithRelativePath
	} from "../plugins/variables.js";

	export default defineComponent({
		components: {
			IonButton,
		},
		emits: [
			"send-to-player"
		],
		props: {
			collection: null,
		},
		watch: { 
			collection: function(newVal) { 
				if(newVal) this.manageContainers(newVal)
				else this.data = null
			}
		},
		data() { return {
			backendURLWithRelativePath,
			data: null,
			languages: {}
		}},
		methods: {
			manageContainers(newVal) {
				// console.log("Data", newVal)
				this.languages = {};
				for(var i = newVal.length -1; i >= 0 ; i--){
					try {
						var element= newVal[i];
						if ('container' in element && 'name' in element.container) {
							var langs = this.languages[element.container.id]
							// console.log("Inside because element has container and container.name")
							// if the container already exists, then push into it the new element
							// Otherwise, create a new array, and push inside the first element
							// console.log("Creating language obj")
							const language = {
								"id": element.language.id,
								"language_name": element.language.name,
								"playback_url": element.content_url,
								"embeddable_url": element.embeddable_url,
								"language_code": element.language.code,
								"default": (element.language.code == this.$store.getters.getBrowserLang)
							}
							// console.log("Language", language)
							// console.log("Languages", langs)
							if (langs) {
								// I have already encountered this container, Must splice from data
								// Add the language only if not already present
								if (!langs.find(x => x.id == language.id)) {
									langs.push(language);
									newVal.splice(i, 1);
								}
							} else {
								// This is the first time I encounter this container, I don't splice it
								this.languages[element.container.id] = [language];
							}
						}
					}  catch(e) {
						console.log(e)
					}
				}
				this.data = newVal
			}
		}
	});
</script>