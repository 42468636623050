<template>
    <div class="description">
        <p v-html="text"></p>
    </div>
</template>

<style scoped>
    .description {
        padding: 1em;
    }
</style>

<script>
    import {
        defineComponent
    } from "vue";

    export default defineComponent({
        props: {
            text: null
        }
    });
</script>