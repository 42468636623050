import { toastController } from "@ionic/vue";
import { backendUrl, publicAccess } from "./variables.js";

export async function checkUserSession(response) {
    //Temporanea, il messaaggio, in realtà, andrebbe preso as-is dal Backend
    const toast = await toastController.create({
        message: "Sessione scaduta", //response.data.error,
        duration: 2000,
    });
    if (response.status === 401) {
		if(publicAccess) this.$router.push("/");
		else this.$router.push("/login");
        return toast.present();
    }
}

export async function openToast(response) {
    //Temporanea, il messaaggio, in realtà, andrebbe preso as-is dal Backend
	if (response && response.status === 401) {
        response.data.error="Utente o password non validi"
    }

    const toast = await toastController.create({
		message: response.data.error,
		duration: 2000,
	});
	if (response && response.status === 401) {
		if(publicAccess) this.$router.push("/");
		else this.$router.push("/login");
	}
	return toast.present();
}

export function getUserInfo() {
    if (localStorage.getItem("user")) {
        let myUser = JSON.parse(localStorage.getItem("user"));
        if (myUser.avatar_url) {
            myUser.avatar_url = `${backendUrl}${myUser.avatar_url}`
        }
        return myUser;
        // console.log(this.currentData.user.avatar_url);
        // this.getTablesData();
    }
}

export function truncate(text, length, clamp) {
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
}

export function stripHTML(htmlString) {
    var div = document.createElement("DIV");
    div.innerHTML = htmlString;
    var text = div.textContent || div.innerText || "";
    return text;
}