<template>
	<ion-page>
		<div class="page-top">
			<!-- Header //-->
			<deventa-header></deventa-header>

			<!-- Categories Selector //-->
			<deventa-categories></deventa-categories>
		</div>
		<!-- Main functionality //-->
		<div class="page-contents">
			<ion-item color="primary">
				<ion-icon id="category-title" slot:icon-only :icon="grid"></ion-icon>
				{{ title }}&nbsp;
				<!-- <ion-spinner id="category-spinner" :class="spinnerClass" color="light"></ion-spinner> -->
			</ion-item>
			<div class="top-content">
				<div class="row">
					<div class="left-content">
						<!--Content Top Left: Video, ma quale? Io partirei come nello standard
						solo il placeholder e poi se uno vuole lo seleziona da playlist-->
						<!-- <deventa-player playerName="playerForCategory" :removedControls="[ 'settings' ]" ref="op2js"
							v-on:is-loading="setSpinnerVisibility"></deventa-player> -->
						<iframe class="video-wrapper" name="deventa-player-od" title="Deventa Player" :src="embeddableUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="width: 100%;height: 100%;"></iframe>
					</div>
					<div class="right-content">
						<deventa-description :text="description"></deventa-description>
					</div>
				</div>
			</div>
			<div class="bottom-content">
				<div class="row">
					<div class="left-content">
						<!--Content Bottom Left: playlist relativa al'evento-->
						<deventa-on-demand-videos :collection="videos" v-on:send-to-player="loadOnDemandVideo">
						</deventa-on-demand-videos>
					</div>
					<div class="right-content">
						<deventa-on-demand-documents :collection="documents"></deventa-on-demand-documents>
					</div>
				</div>
				<!-- Footer //-->
				<deventa-footer></deventa-footer>
			</div>
		</div>
	</ion-page>
</template>

<style scoped>
	.page-top {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		/* height: min(max(calc(6vw + 2em), calc(3.5em + 2em)), calc(2em + 86px)); */
	}

	.page-contents {
		position: absolute;
		top: calc(max(6vw, 3.5em) + 1.8em);
		bottom: 0;
		left: 0;
		right: 0;
		overflow-y: auto;
		overflow-x: hidden;
	}

	#category-title {
		margin-right: .3em;
		padding-right: .3em;
		border-right: 2px solid white;
	}

	.hidden {
		display: none !important;
	}

	/* 
		The content is fixed at 16:9.
		Remember: if you change the width, you must change 
		the first value of the calc for the height accordingly.
	*/
	.top-content .row .left-content {
		width: 50vw;
		height: calc(50vw * 0.5625);
	}

	.top-content .row .right-content {
		width: 50vw;
	}

	.bottom-content .row .left-content {
		width: 50vw;
	}

	.bottom-content .row .right-content {
		width: 50vw;
	}

	@media screen and (min-width: 880px) {
		.row {
			display: flex;
			flex-direction: row;
		}
	}
</style>

<script>
	import {
		defineComponent
	} from "vue";

	import {
		IonPage,
		IonItem,
		IonIcon,
		// IonSpinner
	} from "@ionic/vue"

	import {
		grid
	} from "ionicons/icons";

	import {
		getUserInfo,
		checkUserSession
	} from "../plugins/common.js";

	import DeventaFooter from "../components/DeventaFooter.vue";
	import DeventaHeader from "../components/DeventaHeader.vue";
	import DeventaCategories from "../components/DeventaCategories.vue";
	// import DeventaPlayer from "../components/DeventaPlayer.vue";
	import DeventaOnDemandVideos from "../components/DeventaOnDemandVideos.vue";
	import DeventaOnDemandDocuments from "../components/DeventaOnDemandDocuments.vue";
	import DeventaDescription from "../components/DeventaDescription.vue";

	import {
		backendUrl,
		chatURL,
		backendRelativePath,
		backendURLWithRelativePath
	} from "../plugins/variables.js";
	export default defineComponent({
		name: "Category",
		components: {
			IonIcon,
			IonItem,
			IonPage,
			DeventaFooter,
			DeventaHeader,
			DeventaCategories,
			// DeventaPlayer,
			DeventaOnDemandDocuments,
			DeventaOnDemandVideos,
			DeventaDescription,
			// IonSpinner
		},
		data() {
			return {
				backendUrl,
				chatURL,
				backendRelativePath,
				backendURLWithRelativePath,
				grid,
				getUserInfo,
				checkUserSession,
				$placeholderUrl: `${this.backendURLWithRelativePath}/embeddable/placeholder`,
				embeddableUrl: null,
				// isFirstLoad: true,
				videos: null,
				documents: null,
				title: null,
				description: null,
				languages: {},
				spinnerClass: "hidden"
			};
		},
		watch: {
			$route(to, from) {
				// Only useful, this watch, when navigating from a category to another
				// because ionViewWillEnter is not fired.
				if (from.name == "Category" && to.name == "Category") {
					this.$refs.op2js.initPlayer()

					this.insertVisitedPages();
					this.getContent()
				}
			}
		},
		ionViewWillEnter() {
			console.log(`<ionViewWillEnter> Category`);
			// When entering the Play page, init the player
			// thus we are sure it's initialized
			// this.$refs.op2js.initPlayer()

			// if (this.isFirstLoad === true) {
			this.insertVisitedPages();
			this.getContent()
			// }
		},
		ionViewDidEnter() {
			console.log(`<ionViewDidEnter> Category`);
			// this.isFirstLoad = false;
		},
		ionViewWillLeave() {
			console.log(`<ionViewWillLeave> Category`);
			this.embeddableUrl = this.$placeholderUrl
		},
		ionViewDidLeave() {
			console.log(`<ionViewDidLeave> Category`);
			// When leaving Play page, 
			// Destroy the player, so, the next time you get in
			// it will be reset.
			// this.$refs.op2js.destroyPlayer()
		},
		mounted() {
			console.log(`MOUNTED Category`);
		},
		created() {
			console.log(`CREATED Category`);
		},
		methods: {
			updateChilds() {},
			getContent() {
				this.$store
					.dispatch("tables/fetch", {
						model: `playlists/${this.$route.params.id}`,
					})
					.then((data) => {
						this.title = data.name
						this.description = data.description.body;
					}).catch((error) => {
						this.checkUserSession(error);
					})
				this.documents = null;
				this.$store
					.dispatch("tables/fetch", {
						model: `uploads?q[documents]=true&q[with_attached_document]=true&q[playlists_id_eq]=${this.$route.params.id}`,
					})
					.then((data) => {
						this.documents = data;
					}).catch((error) => {
						this.checkUserSession(error);
					})
				this.videos = null;
				this.$store
					.dispatch("tables/fetch", {
						model: `uploads?q[videos]=true&q[with_attached_document]=true&q[playlists_id_eq]=${this.$route.params.id}`,
					})
					.then((data) => {
						this.manageContainers(data)
						this.videos = data;
					}).catch((error) => {
						this.checkUserSession(error);
					})
			},
			loadOnDemandVideo(data_id, url) {
				this.insertUploadUsers(data_id);
				this.embeddableUrl = url
				// this.$refs.op2js.sendToPlayer(playback_url, languages);
			},
			// setSpinnerVisibility(value) {
			// 	this.spinnerClass = value ? "visible" : "hidden"
			// },
			manageContainers(data) {
				// console.log("Data", data)
				data.forEach((element, index) => {
					try {
						if ('container' in element && 'name' in element.container) {
							var langs = this.languages[element.container.id]
							// console.log("Inside because element has container and container.name")
							// if the container already exists, then push into it the new element
							// Otherwise, create a new array, and push inside the first element
							// console.log("Creating language obj")
							const language = {
								"id": element.language.id,
								"language_name": element.language.name,
								"playback_url": element.content_url,
								"language_code": element.language.code,
								"default": (element.language.code == this.$store.getters
									.getBrowserLang)
							}
							// console.log("Language", language)
							// console.log("Languages", langs)
							if (langs) {
								// I have already encountered this container, Must splice from data
								// Add the language only if not already present
								if (!langs.find(x => x.id == language.id)) {
									langs.push(language);
									data.splice(index, 1)
								}
							} else {
								// This is the first time I encounter this container, I don't splice it
								this.languages[element.container.id] = [language];
							}
						}
					} catch (e) {
						console.log(e)
					}
				})
			},
			insertUploadUsers(videoId) {
				let activity = {
					upload_user: {
						upload_id: videoId,
						user_id: this.getUserInfo().id,
					},
				};
				this.$store
					.dispatch("tables/create", {
						model: "upload_users",
						data: activity
					})
					.catch((error) => {
						this.checkUserSession(error);
					})
			},
			insertVisitedPages() {
				let activity = {
					visited_page: {
						page: this.$route.name,
						user_id: this.getUserInfo().id,
						playlist_id: this.$route.params.id,
					},
				};
				this.$store.dispatch("tables/create", {
					model: "visited_pages",
					data: activity
				}).catch((error) => {
					this.checkUserSession(error);
				})

				// To Google analytics
				this.$gtag.pageview({
					page_path: this.$route.path,
				})
			},
		},
	});
</script>