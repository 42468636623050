<template>
	<ion-page>
		<div class="page-top">
			<!-- Header //-->
			<!-- <deventa-header :backend-url="backendURLWithRelativePath"></deventa-header> -->

			<!-- Categories Selector //-->
			<deventa-categories></deventa-categories>
		</div>

		<!-- Main functionality //-->
		<div class="page-contents">
			<ion-item color="primary">
				<!-- <ion-icon id="play-title" slot:icon-only
					:icon="currentController === 'events' ? pulseOutline : videocamSharp"></ion-icon> -->
				{{ ('name' in currentPlay) ? currentPlay.name : currentPlay.safe_title }}&nbsp;
				<ion-spinner id="play-spinner" :class="spinnerClass" color="light"></ion-spinner>
				<span :class="timeoutClass"><span class="timeout-container">{{timeoutToStart}}</span></span>
			</ion-item>
			<div class="top-content">
				<div class="row">
					<div class="left-content">
						<!--Content Top Left: Video, ma quale? Io partirei come nello standard solo il placeholder e poi se uno vuole lo seleziona da playlist-->
						<deventa-player playerName="playerForPlay" :removedControls="[
							'settings',
							'fullscreen'
						]" ref="opjs" v-on:is-loading="setSpinnerVisibility"></deventa-player>
						<!-- <div class="curiosity-banner">
							Banner Example
						</div> -->
					</div>
					<div class="right-content">
						<!-- <div class="above">
							<div class="thanks-to"><strong>Thanks to:</strong></div>
							<div class="sponsors">
								<img :src="require(`../assets/sponsor/SPONSOR_1_Edwards_logo.png`)"
									:class="`sponsor ${firstSponsorClass}`">
								<img :src="require(`../assets/sponsor/SPONSOR_2_Medtronic_logo.png`)"
									:class="`sponsor ${secondSponsorClass}`">
							</div>
						</div> -->
						<div class="below">
							<div class="chat-container">
								<iframe src="https://www5.cbox.ws/box/?boxid=925978&boxtag=LtqIkm" width="100%"
									height="100%" allowtransparency="yes" allow="autoplay" frameborder="0"
									marginheight="0" marginwidth="0" scrolling="auto"></iframe>
							</div>
							<div class="survey-container">
								<iframe src="https://app.sli.do/event/8q5avfac" height="100%" width="100%"
									frameBorder="0" title="Slido"></iframe>
							</div>
						</div>
						<!--<div>
							Qui ci sarà il QandA Ma solo per i controller di tipo events
							(quindi i live), cosa carichiamo qui nel caso di controller di
							tipo uploads (quindi gli on demand)?
						</div>-->
						<!-- <div class="qanda" v-if="currentController === 'events'">
							<deventa-questions-and-answers></deventa-questions-and-answers>
						</div>
						<deventa-description v-else :text="currentPlayDescription"></deventa-description> -->
					</div>
				</div>
			</div>
			<div class="bottom-content">
				<!-- <div class="row">
					<div class="left-content">
						<deventa-on-demand-videos :collection="videos" v-on:send-to-player="loadOnDemandVideo">
						</deventa-on-demand-videos>
					</div>
					<div class="right-content">
						<deventa-on-demand-documents :collection="documents"></deventa-on-demand-documents>
					</div>
				</div> -->
				<!-- Footer //-->
				<!-- <deventa-footer v-if="!disableFooter"></deventa-footer> -->
			</div>
		</div>
	</ion-page>
</template>

<style scoped>

	.ion-page {
		background-color: var(--ion-color-primary);
	}

	#play-title {
		margin-right: .3em;
		padding-right: .3em;
		border-right: 2px solid white;
	}

	.description {
		padding: 1em;
	}

	.page-top {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}

	.page-contents {
		position: absolute;
		/* top: calc(max(6vw, 3.5em) + 1.8em); */
		top: 1.8em;
		bottom: 0;
		left: 0;
		right: 0;
		overflow-y: auto;
		overflow-x: hidden;
	}

	#category-title {
		margin-right: .3em;
		padding-right: .3em;
		border-right: 2px solid white;
	}

	.curiosity-banner {
		background-color: var(--ion-color-tertiary);
		width: 100%;
		height: 10em;
		color: var(--ion-color-tertiary-contrast);
		padding: 1em;
	}

	.hidden {
		display: none !important;
	}

	.timeout {
		margin-left: auto;
		/* display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top:0;
		bottom:0;
		right:0;
		left:0;
		background-color: rgba(180,180,180,0.9);
		z-index: 5000; */
	}

	/* .timeout-container {
		display: block;
		padding: 2em;
		border-radius: 2em;
		background-color: var(--ion-color-primary);
	} */

	/* 
		The content is fixed at 16:9.
		Remember: if you change the width, you must change 
		the first value of the calc for the height accordingly.
	*/
	.top-content .row .left-content {
		width: 80vw;
		height: calc(80vw * 0.5625);
		position: relative;
		background-color: black;
	}

	.top-content .row .right-content {
		width: 20vw;
		position: relative;
		display: flex;
		flex-direction: column;
	}

	/* .bottom-content .row .left-content {
		width: 50vw;
	}

	.bottom-content .row .right-content {
		width: 50vw;
	} */

	.above {
		display: none;
		/* background-color: var(--ion-color-primary);
		width: 100%;
		height: 15%;
		display: none;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative; */
	}

	.thanks-to {
		padding-left: 1em;
		width: 20%;
		position: relative;
		padding-bottom: 1vw;
	}

	.sponsors {
		width: 80%;
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.below {
		background-color: var(--ion-color-primary);
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		/* height: 85%; */
		height: 100%;
	}

	.row div.right-content .survey-container,
	.row div.right-content .chat-container {
		position: relative;
		padding-left: 1vw;
		padding-right: 1vw;
		padding-bottom: 1vw;
	}


	.row div.right-content .survey-container {
		width: 100%;
		height: 50%;
	}

	.row div.right-content .chat-container {
		width: 100%;
		height: 50%;
	}

	@keyframes in {
		0% {
			opacity: 0;
			visibility: hidden;
		}

		100% {
			opacity: 1;
			visibility: visible;
		}
	}

	@keyframes out {
		0% {
			opacity: 1;
			visibility: visible;
		}

		100% {
			opacity: 0;
			visibility: hidden;
		}
	}

	.sponsor {
		height: 60%;
	}

	.sponsor.show {
		animation: in 700ms ease both;
		position: absolute;
	}

	.sponsor.hide {
		animation: out 700ms ease both;
		position: absolute;
	}

	.row {
		display: flex;
		flex-direction: row;
	}

	@media screen and (max-width: 800px) {
		.top-content .right-content {
			width: auto;
		}

		.row {
			flex-direction: column;
		}

		.top-content .row .left-content {
			width: 100vw;
			height: calc(100vw * 0.5625);
		}

		.below {
			width: 100vw;
			flex-direction: row;
		}

		.row div.right-content .survey-container {
			width: 50%;
			height: 50vh;
		}

		.row div.right-content .chat-container {
			width: 50%;
			height: 50vh;
		}
	}
</style>

<script>
	import {
		defineComponent
	} from "vue";
	import {
		pulseOutline,
		videocamSharp
	} from "ionicons/icons"
	import {
		IonPage,
		// IonIcon,
		IonItem,
		IonSpinner
	} from "@ionic/vue"

	import {
		getUserInfo,
		checkUserSession
	} from "../plugins/common.js";

	// import DeventaFooter from "../components/DeventaFooter.vue";
	// import DeventaHeader from "../components/DeventaHeader.vue";
	import DeventaCategories from "../components/DeventaCategories.vue";
	import DeventaPlayer from "../components/DeventaPlayer.vue";
	// import DeventaQuestionsAndAnswers from "../components/DeventaQuestionsAndAnswers.vue";
	// import DeventaOnDemandVideos from "../components/DeventaOnDemandVideos.vue";
	// import DeventaOnDemandDocuments from "../components/DeventaOnDemandDocuments.vue";
	// import DeventaDescription from "../components/DeventaDescription.vue";

	import {
		backendUrl,
		chatURL,
		backendRelativePath,
		backendURLWithRelativePath,
		disableFooter
	} from "../plugins/variables.js";

	import * as moment from 'moment-timezone';
	require("moment-countdown");

	export default defineComponent({
		name: "Play",
		components: {
			IonPage,
			// DeventaFooter,
			// DeventaHeader,
			DeventaCategories,
			DeventaPlayer,
			// DeventaQuestionsAndAnswers,
			// DeventaOnDemandDocuments,
			// DeventaOnDemandVideos,
			// IonIcon,
			IonItem,
			IonSpinner,
			// DeventaDescription
		},
		data() {
			return {
				backendUrl,
				chatURL,
				backendRelativePath,
				backendURLWithRelativePath,
				pulseOutline,
				videocamSharp,
				getUserInfo,
				checkUserSession,
				isFirstLoad: true,
				currentController: null,
				currentContainer: null,
				currentLang: null,
				currentId: null,
				user: null,
				currentPlay: {},
				currentTags: null,
				videos: [],
				documents: [],
				currentPlayDescription: null,
				// Must make languages not observable
				// since it's used in specific moents I don't need to 
				// react at it's change, so: prefix with $ and it 
				// will be a normal hash instead of an observable, reactive proxy
				$languages: {},
				spinnerClass: "visible",
				timeoutClass: "timeout",
				$startsAt: 0,
				timeoutToStart: "",
				$countdownTimer: null,
				firstSponsorClass: "show",
				secondSponsorClass: "hide",
				disableFooter
			};
		},
		created() {
			console.log(`CREATED Play`);
		},
		ionViewWillEnter() {
			console.log(`<ionViewWillEnter> Play`);
			// When entering the Play page, init the player
			// thus we are sure it's initialized
			this.$refs.opjs.initPlayer()
			this.loadPage();
			//if (this.isFirstLoad === true) this.insertVisitedPages();
			this.insertVisitedPages();
		},
		mounted() {
			console.log(`MOUNTED Play`);
		},
		ionViewDidEnter() {
			console.log(`<ionViewDidEnter> Play`);
			this.isFirstLoad = false;
		},
		ionViewWillLeave() {
			console.log(`<ionViewWillLeave> Play`);
		},
		ionViewDidLeave() {
			console.log(`<ionViewDidLeave> Play`);
			// When leaving Play page, 
			// Destroy the player, so, the next time you get in
			// it will be reset.
			this.$refs.opjs.destroyPlayer()
		},
		watch: {
			$route() {
				console.log(
					`WATCH Play.${this.$route.name}.${this.$route.params.id}.${this.$route.query.controller}`);
			}
		},
		methods: {
			moment,
			countdown() {
				var countObj = moment().countdown(this.$startsAt)
				if (countObj.value <= 0) {
					clearInterval(this.$countdownTimer)
					this.timeoutClass = "hidden"
				}
				this.timeoutToStart = countObj.toString()
			},
			updateChilds() {},
			rotateSponsors() {
				var first = document.querySelector(".sponsors img:first-of-type")
				var last = document.querySelector(".sponsors img:last-of-type")
				if (first.classList.contains("show")) {
					first.classList.replace("show", "hide")
					last.classList.replace("hide", "show")
				} else {
					last.classList.replace("show", "hide")
					first.classList.replace("hide", "show")
				}
			},
			loadPage() {
				// Ogni 20 secondi, ruota gli sponsor
				// setInterval(this.rotateSponsors, 3600000) // One hour
				// Make the play page never expire
				// Getting from the querystring and url the params to identify the type of player
				this.currentController = this.$route.query.controller;
				this.currentContainer = this.$route.query.container;
				this.currentLang = this.$route.query.lang;
				this.currentId = this.$route.params.id;
				this.user = getUserInfo();
				this.$store
					.dispatch("tables/fetch", {
						model: `${this.currentController}/${this.currentId}`
					})
					.then((data) => {
						let playbackUrl, dataId, otherLanguages;
						this.currentPlay = data;
						console.log("Data", data)
						if (data.description) this.currentPlayDescription = data.description.body
						// To simplify later reuse and don't use CPU time to redo again and again the same flatMap
						if (data.tags)
							this.currentTags = data.tags.flatMap((t) => t.name).join(",");
						// Managing the playback
						if ('lives' in data) {
							// this is a live content
							if (data.lives.length > 0) this.loadLiveVideo(data.lives[0].playback_url, data
								.lives)
							// Managing the countdown
							this.$startsAt = data.starts_at
							this.$countdownTimer = setInterval(this.countdown, 1000)
						} else if ('container' in data) {
							dataId = data.id
							// I need to get the other languages of this container
							this.$store.dispatch("tables/fetch", {
								model: `uploads?q[container_id_eq]=${data.container.id}`
							}).then((data) => {
								this.manageContainers(data)
								// This is an ondemand content with fake-multilanguage
								// console.log("Data", data)
								console.log("Try for playbackUrl", this.currentContainer, this
									.currentLang, this.$languages)
								if (this.currentContainer && this.$languages) {
									console.log("theLanguages", this.currentContainer, this.$languages[
										this.currentContainer])
									otherLanguages = this.$languages[this.currentContainer]
									var foundLang = otherLanguages.find((x) => {
										// If currentLang is passed, try to find it and use it's playbackURL
										// Otherwise, look if there's en language
										// Finally, get the first language available
										if (this.currentLang)
											return x.id == this.currentLang
										else
											return x.language_code == "en"
									});
									playbackUrl = (foundLang ? foundLang : otherLanguages[0])
										.playback_url
								}
								this.loadOnDemandVideo(dataId, playbackUrl, otherLanguages);
							}).catch((error) => {
								console.log("ERROR", error)
								this.checkUserSession(error);
							})
						} else if ('content_url' in data) {
							// This is an ondemand content
							dataId = data.id
							if (!playbackUrl) playbackUrl = data.content_url;
							this.loadOnDemandVideo(dataId, playbackUrl, otherLanguages);
						}

						// Ok, the currentTags are filled, I can ask for documents and videos which
						// have the same tags
						this.documents = null;
						this.$store
							.dispatch("tables/fetch", {
								model: `uploads?q[documents]=true&q[filter_by_tags]=${this.currentTags}`,
							})
							.then((data) => {
								this.documents = data;
								// documents loaded, ask for videos to the server
								this.videos = null;
								this.$store
									.dispatch("tables/fetch", {
										model: `uploads?q[videos]=true&q[filter_by_tags]=${this.currentTags}`,
									})
									.then((data) => {
										this.manageContainers(data)
										this.videos = data;
									}).catch((error) => {
										this.checkUserSession(error);
									})
							}).catch((error) => {
								this.checkUserSession(error);
							})
					}).catch((error) => {
						this.checkUserSession(error);
					})
			},
			loadOnDemandVideo(data_id, playback_url, languages) {
				this.insertUploadUsers(data_id);
				this.$refs.opjs.sendToPlayer(playback_url, languages);
			},
			loadLiveVideo(url, languages) {
				this.insertEventUsers();
				this.$refs.opjs.sendToPlayer(url, languages);
			},
			setSpinnerVisibility(value) {
				console.log("Is Loading? ", value)
				this.spinnerClass = value ? "visible" : "hidden"
			},
			manageContainers(data) {
				// console.log("Data", data)
				data.forEach((element, index) => {
					try {
						if ('container' in element && 'name' in element.container) {
							var langs
							if (this.$languages) langs = this.$languages[element.container.id]
							// console.log("Inside because element has container and container.name")
							// if the container already exists, then push into it the new element
							// Otherwise, create a new array, and push inside the first element
							// console.log("Creating language obj")
							const language = {
								"id": element.language.id,
								"language_name": element.language.name,
								"playback_url": element.content_url,
								"language_code": element.language.code,
								"default": (element.language.code == this.$store.getters
									.getBrowserLang)
							}
							// console.log("Language", language)
							// console.log("Languages", langs)
							if (langs) {
								// I have already encountered this container, Must splice from data
								// Add the language only if not already present
								if (!langs.find(x => x.id == language.id)) {
									langs.push(language);
									data.splice(index, 1)
								}
							} else {
								// This is the first time I encounter this container, I don't splice it
								if (this.$languages) {
									this.$languages[element.container.id] = [language];
								} else {
									this.$languages = {}
									this.$languages[element.container.id] = [language];
								}
							}
						}
					} catch (e) {
						console.log(e)
					}
				})
			},
			insertEventUsers() {
				let activity = {
					event_user: {
						event_id: this.currentId,
						user_id: this.user.id,
					},
				};
				this.$store
					.dispatch("tables/create", {
						model: "event_users",
						data: activity
					})
					.catch((error) => {
						this.checkUserSession(error);
					})
			},
			insertUploadUsers(videoId) {
				let activity = {
					upload_user: {
						upload_id: videoId,
						user_id: this.user.id,
					},
				};
				this.$store
					.dispatch("tables/create", {
						model: "upload_users",
						data: activity
					})
					.catch((error) => {
						this.checkUserSession(error);
					})
			},
			insertVisitedPages() {
				let activity = {
					visited_page: {
						page: this.$route.name,
						user_id: this.user.id,
						event_id: this.$route.query.controller === "events" ? this.$route.params.id : null,
					},
				};
				this.$store.dispatch("tables/create", {
					model: "visited_pages",
					data: activity
				}).catch((error) => {
					this.checkUserSession(error);
				})
				// To Google analytics
				this.$gtag.pageview({
					page_path: this.$route.path,
				})
			},
		},
	});
</script>