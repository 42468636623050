<template>
	<div class="the-footer">
		<div class="top-footer">
			<img :src="require(`@/assets/logo_application.png`)" />
		</div>
		<div class="bottom-footer">
			<a href="#">
				<ion-icon size="small" color="dark" :icon="logoFacebook"></ion-icon>
			</a>
			<a href="#">
				<ion-icon size="small" color="dark" :icon="logoYoutube"></ion-icon>
			</a>
			<a href="#">
				<ion-icon size="small" color="dark" :icon="logoLinkedin"></ion-icon>
			</a>
		</div>
	</div>
</template>

<style scoped>
	/* PAGE FOOTER START */
	.the-footer {
		position: relative;
		background-color: var(--ion-color-medium);
		height: 5em;
		width: 100%;
	}

	.top-footer {
		display: flex;
		justify-content: center;
		text-align: center;
		height: 3em;
		padding-top: .3em;
	}

	.bottom-footer {
		display: flex;
		justify-content: center;
		align-content: top;
		align-items: top;
		margin-top: .3em;
	}

	.bottom-footer>a {
		padding-right: 5px;
		padding-left: 5px;
	}

	.chat {
		width: 40vw;
		position: absolute;
		right: 0;
		top: -1.5em;
		box-shadow: 10px 10px 5px grey;
		z-index: 29999;
	}

	.chat ion-icon {
		margin-right: .3em;
		padding-right: .3em;
		border-right: 2px solid white;
	}

	#the-actual-chat {
		position: absolute;
		right: 0;
		bottom: 3em;
		width: 100%;
		height: 50vh;
		background-color: white;
		border: 1px solid var(--ion-color-light);
		border-top-left-radius: 2em;
		display: none;
	}

	/* Tablets */
	@media screen and (max-width: 1080px) {}

	/* PAGE FOOTER END */
</style>

<script>
	import {
		IonIcon,
		// IonItem 
	} from "@ionic/vue";
	import {
		logoLinkedin,
		logoFacebook,
		logoYoutube,
		chatbubblesSharp
	} from "ionicons/icons";

	import {
		defineComponent
	} from "vue";
	import {
		getUserInfo
	} from "../plugins/common.js";


	import {
		disableChat
	} from "../plugins/variables.js";

	export default defineComponent({
		components: {
			IonIcon,
			// IonItem
		},
		data() {
			return {
				user: null,
				logoLinkedin,
				logoFacebook,
				logoYoutube,
				chatbubblesSharp
			};
		},
		props: {
			appLogo: {
				type: String,
				default: "../assets/logo_application.png", //"@/assets/logo_application.png",
			}
		},
		watch: {
			$route(from, to) {
				if(to.name == "Login")
					// TODO: Destroy Chat
					console.log("TODO: Destroy Chat")
				else {
					this.user = getUserInfo();
					this.createChat(document, "script");
				}
			}
		},
		mounted() {
			this.user = getUserInfo();
			if(!disableChat) this.createChat(document, "script");
		},
		created() {},
		methods: {
			createChat(d, t) {
				var helpdesk_url = this.user.helpdesk_url
				var helpdesk_cable_url = this.user.helpdesk_cable_url
				var helpdesk_app = this.user.helpdesk_app
				var jwe_data = this.user.jwe_data
				var user_email = this.user.email
				var user_name = (this.user.fullname || this.user.username || "Nessun Nome")

				console.log("helpdesk_url",helpdesk_url)
				console.log("helpdesk_cable_url",helpdesk_cable_url)
				console.log("helpdesk_app",helpdesk_app)
				console.log("jwe_data",jwe_data)
				console.log("user_email",user_email)
				console.log("user_name",user_name)


				if(helpdesk_url){
					var g = d.createElement(t),
						s = d.getElementsByTagName(t)[0];
					g.src = `${helpdesk_url}/embed.js`
					s.parentNode.insertBefore(g, s);
					g.onload = function () {
						new window.ChaskiqMessengerEncrypted({
							domain: helpdesk_url,
							ws: helpdesk_cable_url,
							app_id: helpdesk_app,
							data: {
								email: user_email,
								identifier_key: jwe_data,
								properties: {
									name: user_name,
									email: user_email
								}
							},
							// lang: "USER_LANG_OR_DEFAULTS_TO_BROWSER_LANG" 
						})
					}
				}
			},
		},
	});
</script>