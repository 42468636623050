<template>
	<div class="utg">
		<div v-if="collections.untagged && collections.untagged.length > 0">
			<ion-item lines="none" class="tag-heading ion-no-border" color="clear">
				<ion-icon slot:icon-only :icon="listSharp"></ion-icon>
				NON APPARTENENENTI A PLAYLIST
				<ion-icon class="pointer" slot="end" :icon="arrowBackCircle" style="border: none;" v-on:click="sLeft"
					data-target="untagged-od"></ion-icon>
				<ion-icon class="pointer" slot="end" :icon="arrowForwardCircle" style="border: none;" v-on:click="sRight"
					data-target="untagged-od"></ion-icon>
			</ion-item>
		</div>
		<div class="fader-container">
			<div id="untagged-od" class="bottom-content-row" v-if="'untagged' in collections">
				<!-- <div class="fader-start"></div>
				<div class="fader-end"></div> -->
				<div v-for="upload in collections.untagged" :key="upload.id" class="card uploaded-element"
					:id="`uploaded-element-${upload.id}`">
					<div class="bottom-content-row-thumbnail" v-if="upload.filetype">
						<router-link v-if="upload.filetype === 'video'"
							:to="`/play/${upload.id}?controller=uploads${upload.container && 'name' in upload.container ? '&container=' + upload.container.id : ''}`">
							<img v-if="upload.preview_url != null" class="playlist-image" :src="upload.preview_url" />
							<img v-else class="playlist-image" :src="require(`@/assets/empty_image.png`)" />
						</router-link>
						<a v-else href="#" @click="openUrl(upload)" target="_blank">
							<!-- <img v-if="upload.filetype === 'pdf'" class="playlist-image" :src="`${backendUrl}${upload.placeholder}`" /> -->
							<img v-if="upload.filetype === 'pdf'" class="playlist-image" :src="require(`@/assets/pdf_icon.png`)" />
							<img v-else-if="upload.preview_url != null" class="playlist-image"
								:src="`${backendUrl}${upload.preview_url}`" />
							<!--<img v-else class="playlist-image" :src="require(`@/assets/pdf_icon.png`)" />-->
						</a>
					</div>
					<div class="upload-controls" v-if="upload.filetype">
						<strong>{{ upload.filetype }}</strong>
						<br />
						<small>{{ 'container' in upload && 'name' in upload.container ? upload.container.name : upload.safe_title }}</small>
						<br />
						<small class="uploaded-element-languages" v-if="upload.container && 'name' in upload.container">
							<router-link v-for="lang in languages[upload.container.id]" :key="lang.id"
								class="send-to-player pointer lang"
								:to="`/play/${upload.id}?controller=uploads&container=${upload.container.id}&lang=${lang.id}`">
								<ion-button class="lang-button" size="small" shape="round">{{ lang.language_code }}</ion-button>
							</router-link>
						</small>
						<small class="uploaded-element-languages" v-else-if="!upload.container && upload.filetype === 'video' && upload.language && 'name' in upload.language">
							<router-link class="send-to-player pointer lang"
								:to="`/play/${upload.id}?controller=uploads&lang=${upload.language.id}`">
								<ion-button class="lang-button" size="small" shape="round">{{ upload.language.code }}</ion-button>
							</router-link>
						</small>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-if="'playlists' in collections" class="tagged-elements-container">
		<div v-for="tag in collections.playlists" :key="tag.id" class="tag" :id="`tag-${tag.id}`">
			<div v-if="tag.uploads && tag.uploads.length > 0">
				<ion-item lines="none" class="tag-heading ion-no-border" color="clear">
					<ion-icon slot:icon-only :icon="listSharp"></ion-icon>{{ tag.name }}
					<ion-icon class="pointer" slot="end" :icon="arrowBackCircle" style="border: none;" v-on:click="sLeft"
						:data-target="`tagged-${tag.id}`"></ion-icon>
					<ion-icon class="pointer" slot="end" :icon="arrowForwardCircle" style="border: none;" v-on:click="sRight"
						:data-target="`tagged-${tag.id}`"></ion-icon>
				</ion-item>
			</div>
			<div class="fader-container">
				<div :id="`tagged-${tag.id}`" class="bottom-content-row" v-if="tag.uploads && tag.uploads.length > 0">
					<div v-for="upload in tag.uploads" :key="upload.id" class="card uploaded-element" :id="`uploaded-element-${upload.id}`">
						<div v-if="upload.filetype">
							<router-link v-if="upload.filetype === 'video'"
								:to="`/play/${upload.id}?controller=uploads${upload.container && 'name' in upload.container ? '&container=' + upload.container.id : ''}`">
								<img v-if="upload.preview_url != null" class="playlist-image" :src="upload.preview_url" />
								<img v-else class="playlist-image" :src="require(`@/assets/empty_image.png`)" />
							</router-link>
							<a v-else href="#" @click="openUrl(upload)" target="_blank">
							<!--<a v-else :href="`${backendUrl}${upload.content_url}`" target="_blank">-->
								<!-- <img v-if="upload.filetype === 'pdf'" class="playlist-image" :src="`${backendUrl}${upload.placeholder}`" /> -->
								<img v-if="upload.filetype === 'pdf'" class="playlist-image" :src="require(`@/assets/pdf_icon.png`)" />
								<img v-else-if="upload.preview_url != null" class="playlist-image"
									:src="`${backendUrl}${upload.preview_url}`" />
								<!--<img v-else class="playlist-image" :src="require(`@/assets/pdf_icon.png`)" />-->
							</a>
						</div>
						<div class="upload-controls" v-if="upload.filetype">
							<strong>{{ upload.filetype }}</strong>
							<br />
							<small>{{ 'container' in upload && 'name' in upload.container ? upload.container.name : upload.safe_title }}</small>
							<br />
							<small class="uploaded-element-languages" v-if="upload.container && 'name' in upload.container">
								<router-link v-for="lang in languages[upload.container.id]" :key="lang.id"
									class="send-to-player pointer lang"
									:to="`/play/${upload.id}?controller=uploads&container=${upload.container.id}&lang=${lang.id}`">
									<ion-button class="lang-button" size="small" shape="round">{{ lang.language_code }}</ion-button>
								</router-link>
							</small>
							<small class="uploaded-element-languages" v-else-if="!upload.container && upload.filetype === 'video' && upload.language && 'name' in upload.language">
								<router-link class="send-to-player pointer lang"
									:to="`/play/${upload.id}?controller=uploads&lang=${upload.language.id}`">
									<ion-button class="lang-button" size="small" shape="round">{{ upload.language.code }}</ion-button>
								</router-link>
							</small>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
	/* TAG = PLAYLIST START */
	.bottom-content-row {
		width: auto;
		overflow: hidden;
		/* height: 120px; */
		overflow-x: scroll;
		overflow-y: hidden;

		margin-bottom: 2em;

		/* Firefox */
		/* background-color: var(--ion-color-secondary); */

		-ms-overflow-style: none;

		/* IE and Edge */
		scrollbar-width: none;

		overflow-x: auto;
		white-space: nowrap;

		position: relative;

	}

	.fader-container {
		position: relative;
		height: 100%;
		overflow: hidden;
	}

	.fader-container:after,
	.fader-container:before {
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		width: max(5vw, 3.5em);
		height: 100%;
		pointer-events: none;
	}

	.card:first-of-type {
		margin-left:  max(5vw, 3.5em);
	}

	.fader-container:after {
		right: 0;
		background-image: linear-gradient(to right, rgba(0,0,0,0), var(--ion-color-tertiary));
	}

	.fader-container:before {
		left: 0;
		background-image: linear-gradient(to left, rgba(0,0,0,0), var(--ion-color-tertiary));
	}

	/* Hide scrollbar for Chrome, Safari and Opera */
	.bottom-content-row::-webkit-scrollbar {
		display: none;
	}

	.bottom-content-row-thumbnail {
		display: flex;
		justify-content: center;
		align-content: center;
		flex-direction: column;
		height: min(125px, 20vw);
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;
	}

	/* .fader-start {
		position: absolute;
		top: 0;
		left: 0;
		width: max(3vw, 2em);
		height: 100%;
		background-image: linear-gradient(to left, rgba(0,0,0,0), var(--ion-color-tertiary));
	}

	.fader-end {
		position: absolute;
		top: 0;
		right: 0;
		width: max(3vw, 2em);
		height: 100%;
		background-image: linear-gradient(to right, rgba(0,0,0,0), var(--ion-color-tertiary));
	} */



	/* .tagged-elements-container {
		background-color: var(--ion-color-secondary);
	} */

	.card {
		margin-right: 3.5em;
		font-size: small;
		text-align: left;
	}

	.card div img {
		height: min(125px, 20vw);
	}

	.tag-heading {
		color: var(--ion-color-primary);
		border: none;
		font-size: max(0.9em, 1.3vw);
	}

	.tag-heading ion-icon {
		padding-right: .3em;
		margin-right: .3em;
		border-right: 2px solid var(--ion-color-primary);
	}

	.playlist-image {
		/* background-image: url('~@/assets/empty_image.png'); */
		background-size: cover;
		border-radius: .7em;
	}

	.upload-controls {
		line-height: 77%;
	}

	small {
		font-size: xx-small;
	}

	.lang {
		font-weight: bold;
		color: var(--ion-color-primary);
		text-decoration: none;
	}

	.lang-button {
		width: 22px;
		height: 20px;
	}

	.uploaded-element {
		display: inline-block;
		vertical-align: top;
	}
	/* Tablets */
	@media screen and (max-width: 1080px) {

	}
	/* TAG = PLAYLIST END */
</style>

<script>
	import {
		IonItem,
		IonIcon,
		IonButton,
	} from "@ionic/vue";
	import {
		defineComponent
	} from "vue";
	import {
		listSharp,
		arrowForwardCircle,
		arrowBackCircle
	} from "ionicons/icons";
	import {
		backendUrl
	} from "../plugins/variables.js";
	import {
		getUserInfo,
		checkUserSession
	} from "../plugins/common.js";

	export default defineComponent({
		components: {
			IonItem,
			IonIcon,
			IonButton,
		},
		data() {
			return {
				backendUrl,
				listSharp,
				arrowForwardCircle,
				arrowBackCircle,
				getUserInfo,
				checkUserSession,
				collections: {},
				languages: {},
				// browserLang: null
			};
		},
		props: {},
		created() {},
		watch: {
			$route() {
				if (this.$route.name == "Home") {
					this.languages = [];
					this.getOnDemand();
				}
			}
		},
		mounted() {
			this.languages = this.$store.getters.getAllLanguages;
			this.getOnDemand();
		},
		methods: {
			sLeft(e) {
				const conent = document.querySelector('#' + e.target.dataset.target);
				// conent.scrollLeft -= conent.offsetWidth;
				conent.scrollBy({
					left: -conent.offsetWidth,
					top: 0,
					behavior: 'smooth'
				})
				e.preventDefault();
			},
			sRight(e) {
				const conent = document.querySelector('#' + e.target.dataset.target);
				// conent.scrollLeft += conent.offsetWidth;
				conent.scrollBy({
					left: conent.offsetWidth,
					top: 0,
					behavior: 'smooth'
				})
				e.preventDefault();
			},
			manageContainers(data) {
				// console.log("Data", data);
				for(var i = data.length -1; i >= 0 ; i--){
					try {
						var element= data[i];
						if ('container' in element && 'name' in element.container) {
							var langs = this.languages[element.container.id]
							// console.log("Inside because element has container and container.name")
							// if the container already exists, then push into it the new element
							// Otherwise, create a new array, and push inside the first element
							// console.log("Creating language obj")
							const language = {
								"id": element.language.id,
								"language_name": element.language.name,
								"playback_url": element.content_url,
								"language_code": element.language.code,
								"default": (element.language.code == this.$store.getters.getBrowserLang)
							}
							// console.log("Language", language)
							// console.log("Languages", langs)
							if (langs) {
								// I have already encountered this container, Must splice from data
								// Add the language only if not already present
								if (!langs.find(x => x.id == language.id)) {
									langs.push(language);
									data.splice(i, 1);
								}
							} else {
								// This is the first time I encounter this container, I don't splice it
								this.languages[element.container.id] = [language];
							}
						}
					}  catch(e) {
						console.log(e)
					}
				}
			},
			getOnDemand() {
				// ?q[with_sessions]=true
				this.$store
					.dispatch("tables/fetch", {
						model: "uploads?q[untagged]=true&q[with_attached_document]=true&q[ok_eq]=true"
					})
					.then((data) => {
						// Get untagged data
						this.collections.untagged = data;
						// Set collection of languages
						this.manageContainers(data)
						this.$store
							.dispatch("tables/fetch", {
								model: `tags?do=get_tagged&t=uploads`,
							})
							.then((data) => {
								this.collections.playlists = data
								for (let tag in data) {
									this.manageContainers(data[tag].uploads)
								}
							})
							.catch((error) => {
								this.checkUserSession(error);
							})
					})
					.catch((error) => {
						this.checkUserSession(error);
					})
			},
			openUrl(data) {
				this.insertUploadUsers(data.id);
				window.open(this.backendUrl + data.content_url, "_blank");
			},
			insertUploadUsers(uploadId) {
				let activity = {
					upload_user: {
						upload_id: uploadId,
						action: "visualization",
						user_id: this.getUserInfo().id,
					},
				};
				this.$store
					.dispatch("tables/create", {
						model: "upload_users",
						data: activity
					})
					.catch((error) => {
						this.checkUserSession(error);
					})
			},
		},
	});
</script>