<template>
	<div class="categories">
		<!-- <router-link to="/home">HOME</router-link>
		<router-link :to="`/category/${record.id}`" v-for="record in categories" :key="record.id" class="menuitem">
			{{ record.name }}
		</router-link> -->
		<span class="pointer menuitem" v-on:click="toggleFullscreen()">Fullscreen</span>
	</div>
</template>

<style scoped>
	/* CATEGORIES START */
	.categories {
		height: 2em;
		line-height: 2em;
		font-size: .9em;
		text-align: right;
		background-color: var(--ion-color-medium);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
	}

	.menuitem {
		padding-right: .4em;
		margin-right: .4em;
		color: white;
		text-decoration: none;
		border-right: 2px solid white;
	}

	.menuitem:last-of-type {
		border: none;
	}

	/* CATEGORIES END */
</style>

<script>
	import {
		defineComponent
	} from "vue";

	import {
		checkUserSession
	} from "../plugins/common.js";

	import { 
		menuController,
		// IonIcon
	} from '@ionic/vue';

	import {
		// expandOutline
	} from "ionicons/icons"

	export default defineComponent({
		components: {
			// IonIcon
		},
		data() {
			return {
				checkUserSession,
				// expandOutline,
				categories: null,
			};
		},
		watch: {
			$route() {
				// this.getCategories();
			}
		},
		mounted() {
			// This way I know the dynamic id passed in the URL
			// console.log(this.$route.params.id)
			// Ok filling what I need to fill
			// this.getCategories();
		},
		props: {},
		methods: {
			toggleFullscreen(elem) {
				elem = elem || document.documentElement;

				if (!document.fullscreenElement && !document.mozFullScreenElement &&
					!document.webkitFullscreenElement && !document.msFullscreenElement) {
					if (elem.requestFullscreen) {
					elem.requestFullscreen();
					} else if (elem.msRequestFullscreen) {
					elem.msRequestFullscreen();
					} else if (elem.mozRequestFullScreen) {
					elem.mozRequestFullScreen();
					} else if (elem.webkitRequestFullscreen) {
					elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
					}
				} else {
					if (document.exitFullscreen) {
					document.exitFullscreen();
					} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
					} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
					} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
					}
				}
			},
			getCategories() {
				this.$store.dispatch("tables/fetch", {
					model: "playlists",
				}).then((data) => {
					this.categories = data;
				}).catch((error) => {
					this.checkUserSession(error);
				})
			},
			openCategoriesMenu() {
				menuController.enable(true, 'first');
				menuController.open('first');
			}
		},
	});
</script>