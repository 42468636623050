<template>
	<!--PlaceHolder Documents List-->
	<div class="bottom-content-row">
		<ion-header color="secondary" lines="none">
			<ion-icon id="documents-title" slot:icon-only :icon="documents"></ion-icon>DOCUMENTS
		</ion-header>
		<div class="cards-container" v-if="collection && collection.length > 0">
			<div class="card" v-for="upload in collection" :key="upload.id">
				<ion-list>
					<ion-item>
						<div class="image">
							<!--<a :href="`${backendUrl}${upload.content_url}`" :target="'_blank'">-->
							<a href="#" @click="openUrl(upload,false)" target="_blank">
								<!-- <img v-if="upload.filetype === 'pdf'" class="playlist-image" :src="`${backendUrl}${upload.placeholder}`" /> -->
								<img v-if="upload.filetype === 'pdf'" class="playlist-image" :src="require(`@/assets/pdf_icon.png`)" />
								<img v-else-if="upload.preview_url != null" class="playlist-image"
									:src="`${backendUrl}${upload.preview_url}`" />
								<img v-else class="playlist-image" :src="require(`@/assets/pdf_icon.png`)" />
							</a>
						</div>
						<div class="info">
							<div>
								<strong>{{ upload.safe_title }}</strong>
							</div>
							<div>
								<small>{{ truncate(stripHTML(upload.description.body), 89) }}</small>
							</div>
							<div>
								<!--<ion-button color="light" :href="`${backendUrl}${upload.content_url}`" target="_blank">-->
								<ion-button color="primary" href="#" @click="openUrl(upload,false)" target="_blank">
									{{ upload.filetype === 'pdf'? "READ" : "VIEW" }}
								</ion-button>
								<!--<ion-button color="light" :href="`${backendUrl}${upload.content_url.replace('=preview', '=attachment')}`">-->
								<ion-button color="primary" @click="openUrl(upload,true)">
									DOWNLOAD
								</ion-button>
							</div>
						</div>
					</ion-item>
				</ion-list>
			</div>
		</div>
		<div class="bottom-content-row" v-else>Nessun documento presente</div>
	</div>
</template>

<style scoped>
	.image {
		width: 125px;
		text-align: center;
	}
	.cards-container {
		height: 100%;
		overflow: auto;
		background: var(--ion-item-background, var(--ion-background-color, #fff));
	}

	/* Hide scrollbar for Chrome, Safari and Opera */
	.bottom-content-row::-webkit-scrollbar {
		display: none;
	}

	.right-content {
		display: flex;
		flex-direction: column;
		justify-content: left;
		align-items: top;
		width: 100%;
		height: 100%;
		background-color: white !important;
	}

	.bottom-content-row .card {
		font-size: small;
		text-align: left;
	}

	.bottom-content-row {
		padding: 1em;
		height: 100%;
		border-top: 2px solid var(--ion-color-primary);
	}

	.bottom-content-row .card div img {
		height: min(125px, 20vw);
	}

	ion-item {
		width: 100%;
		margin-bottom: 1em;
	}

	.info {
		margin-left: .5em;
	}

	#documents-title {
		margin-right: .3em;
		padding-right: .3em;
		border-right: 2px solid var(--ion-color-primary);
	}

	
</style>

<script>
	import {
		defineComponent
	} from "vue";

	import {
		backendUrl
	} from "../plugins/variables.js"

	import {
		truncate,
		stripHTML,
		getUserInfo,
		checkUserSession
	} from "../plugins/common.js";
	import {
		IonList,
		IonItem,
		IonButton,
		IonIcon,
		IonHeader
	} from "@ionic/vue";
	import {
		documents
	} from "ionicons/icons";
	export default defineComponent({
		components: {
			IonList,
			IonItem,
			IonButton,
			IonIcon,
			IonHeader
		},
		data() {
			return {
				truncate,
				stripHTML,
				backendUrl,
				getUserInfo,
				checkUserSession,
				documents
			};
		},
		props: {
			collection: null,
		},
		created() {},
		methods: {
			openUrl(data, download) {
				this.insertUploadUsers(data.id);
				if (download === true) window.open(this.backendUrl + data.content_url.replace("=preview",
					"=attachment"), "_self");
				else window.open(this.backendUrl + data.content_url, "_blank");
			},
			insertUploadUsers(uploadId, download) {
				let activity = {
					upload_user: {
						upload_id: uploadId,
						action: download ? "download" : "visualization",
						user_id: this.getUserInfo().id,
					},
				};
				this.$store
					.dispatch("tables/create", {
						model: "upload_users",
						data: activity
					})
					.catch((error) => {
						this.checkUserSession(error);
					})
			},
		},
	});
</script>