<template>
	<ion-page>
	</ion-page>
</template>

<style scoped>
</style>

<script>
	import {
		defineComponent
	} from "vue";

	import {
		IonPage
	} from "@ionic/vue";
	import {
		openToast
	} from "../plugins/common.js";

	import {
		publicAccess,
		accessToken
	} from "../plugins/variables.js"

	export default defineComponent({
		name: "Root",
		props: {
		},
		components: {
			IonPage,
		},
		data: () => ({
			openToast,
		}),
		ionViewWillEnter() {
			// This will be done before ionViewDidEnter, thus overriding the normal behaviour
			// of asking for a password.
			if (publicAccess) {
				this.autoLogin(accessToken);
			} else if (this.$route.query.atoken) {
				this.autoLogin(this.$route.query.atoken);
			} else this.$router.push("/login");
		},
		methods: {
			autoLogin(token) {
				// console.log("Accessing without password, PUBLIC SERVICE:",accessToken, routePushTo)
				this.$store.dispatch("tables/login", {
					model: "authenticate",
					data: {
						atoken: token,
					},
				}).then((usr) => {
					// console.log("Utente Autenticato ciao", usr);
					if (usr) {
						localStorage.setItem(
							"user",
							JSON.stringify(usr)
						);
						if (publicAccess) {
							// If there's actually a user with data attached, then go to home
							if (usr.next_valid_session) this.$router.push(
								`/play/${usr.next_valid_session}?controller=events`);
							else this.$router.push("/"); // Keep trying to login
						} else this.$router.push("/home");
					}
				}).catch(() => {
					this.$router.push("/"); // Keep trying to login
					// this.openToast(error);
				});
			},
		},
	});
</script>