<template>
	<ion-page>
		<div class="page-top">
			<!-- Header //-->
			<deventa-header></deventa-header>

			<!-- Categories Selector //-->
			<deventa-categories></deventa-categories>
		</div>
		<!-- Main functionality //-->
		<div class="page-contents">
			<div class="top-content">
				<deventa-agenda></deventa-agenda>
			</div>
			<div class="bottom-content">
				<!--Content Bottom: PlayList (generale o relativa all'evento selezionato in
				Agenda, ma non avviato)?-->
				<deventa-on-demand></deventa-on-demand>
				<!-- Footer //-->
				<deventa-footer></deventa-footer>
			</div>
		</div>
	</ion-page>
</template>

<style scoped>
	.page-top {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		/* height: min(max(calc(6vw + 2em), calc(3.5em + 2em)), calc(2em + 86px)); */
	}

	.page-contents {
		position: absolute;
		top: calc(max(6vw, 3.5em) + 1.8em);
		bottom: 0;
		left: 0;
		right: 0;
		overflow-y: auto;
	}

	@media screen and (max-width: 1080px) {}
</style>

<script>
	import {
		defineComponent
	} from "vue";

	import {
		IonPage
	} from '@ionic/vue';

	import DeventaFooter from "../components/DeventaFooter.vue";
	import DeventaHeader from "../components/DeventaHeader.vue";
	import DeventaCategories from "../components/DeventaCategories.vue";
	import DeventaAgenda from "../components/DeventaAgenda.vue";
	import DeventaOnDemand from "../components/DeventaOnDemand.vue";
	import {
		getUserInfo,
		checkUserSession
	} from "../plugins/common.js";

	export default defineComponent({
		name: "Home",
		components: {
			IonPage,
			DeventaFooter,
			DeventaHeader,
			DeventaCategories,
			DeventaAgenda,
			DeventaOnDemand,
		},
		data: () => ({
			getUserInfo,
			checkUserSession,
		}),
		mounted() {
			console.log(`MOUNTED Home`);
		},
		created() {
			console.log(`CREATED Home`);
		},
		ionViewWillEnter() {
			console.log(`<ionViewWillEnter> Home`);
		},
		ionViewDidEnter() {
			console.log(`<ionViewDidEnter> Home`);
			this.insertVisitedPages();
		},
		ionViewWillLeave() {
			console.log(`<ionViewWillLeave> Home`);
		},
		ionViewDidLeave() {
			console.log(`<ionViewDidLeave> Home`);
		},
		watch: {
			$route() {
				console.log(`WATCH Home.${this.$route.name}`);
			}
		},
		methods: {
			updateChilds() {},
			insertVisitedPages() {
				let activity = {
					visited_page: {
						page: this.$route.name,
						user_id: this.getUserInfo().id,
					},
				};
				this.$store.dispatch("tables/create", {
					model: "visited_pages",
					data: activity
				}).catch((error) => {
					this.checkUserSession(error);
				})

				// To Google analytics
				this.$gtag.pageview({
					page_path: this.$route.path,
				})
			},
		},
	});
</script>