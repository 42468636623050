<template>
	<ion-app>
		<ion-router-outlet id="content" />
		<ion-menu side="end" menu-id="first" content-id="content">
			<ion-header>
				<ion-toolbar color="primary">
					<ion-title>Categories</ion-title>
				</ion-toolbar>
			</ion-header>
			<ion-content>
				<ion-list>
					<!-- <ion-item>
						<router-link to="/home">
							HOME
						</router-link>
					</ion-item> -->
					<ion-item button detail="false" v-for="record in categories" :key="record.id">
						<router-link :to="`/category/${record.id}`" v-on:click="closeMenu()">
							{{ record.name }}
						</router-link>
					</ion-item>
				</ion-list>
			</ion-content>
		</ion-menu>
	</ion-app>
</template>

<script>
	import {
		IonApp,
		IonRouterOutlet,
		IonTitle,
		IonToolbar,
		IonHeader,
		IonItem,
		IonList,
		IonContent,
		IonMenu,
		menuController
	} from "@ionic/vue";

	import {
		defineComponent
	} from "vue";

	import {
		checkUserSession
	} from "./plugins/common.js";

	export default defineComponent({
		name: "App",
		components: {
			IonApp,
			IonRouterOutlet,
			IonTitle,
			IonToolbar,
			IonHeader,
			IonItem,
			IonList,
			IonContent,
			IonMenu
		},
		created() {
			this.$store.commit("setBrowserLang");
			// console.log(this.$store.getters.getBrowserLang)
		},
		data() {
			return {
				checkUserSession,
				categories: null,
			};
		},
		watch: {
			$route() {
				this.getCategories();
			}
		},
		mounted() {
			// This way I know the dynamic id passed in the URL
			// console.log(this.$route.params.id)
			// Ok filling what I need to fill
			// this.getCategories();
		},
		methods: {
			getCategories() {
				this.$store.dispatch("tables/fetch", {
					model: "playlists",
				}).then((data) => {
					this.categories = data;
				}).catch((error) => {
					this.checkUserSession(error);
				})
			},
			closeMenu() {
				menuController.close('end');
			}
		},
	});
</script>