<template>
	<ion-item color="primary" v-if="events">
		<ion-icon class="agenda-title" slot:icon-only :icon="calendarSharp"></ion-icon>AGENDA
	</ion-item>
	<div class="top-content-col" v-if="events">
		<div v-for="event in events" :key="event.id">
			<!-- <div v-if="event.events.length > 0"> -->
			<span :set="(firstSessionDateTime = new Date(event.events[0].starts_at))"></span>
			<span :set="(isLive = firstSessionDateTime < Date.now())"></span>
			<div class="event-header" :id="`event-${event.id}`">
				<ion-icon :id="`chevron-${event.id}`" :icon="chevronForwardOutline" class="event-chevron pointer" v-on:click="toggleSessions" :data-session-for="event.id"></ion-icon>
				<!-- <div :id="`chevron-${event.id}`" class="event-chevron pointer">
					<img v-on:click="toggleSessions" :data-session-for="event.id" src="~@/assets/arrow_expand.png" />
				</div> -->
				<div class="events-datetime-description">
					<div class="events-datetime">
						<div class="event-date text-left">
							{{ firstSessionDateTime.toLocaleDateString() }}
						</div>
						<div class="event-time text-left">
							{{ firstSessionDateTime.toLocaleTimeString([], {timeStyle: 'short'}) }}
						</div>
					</div>
					<div class="event-description text-left">
						<strong>{{ event.name }}</strong>
						<span> - {{ truncate(stripHTML(event.description.body), 89) }}</span>
					</div>
					<div class="event-live-now text-left">
						{{ isLive ? "LIVE NOW" : "" }}
					</div>
					<div class="event-placeholder">&nbsp;</div>
				</div>
			</div>
			<div :id="`sessions-for-${event.id}`" class="sessions-container" style="display:none;">
				<div v-for="session in event.events" color="tertiary" :key="session.id" class="session-header">
					<span :set="(check = Date.now())"></span>
					<span :set="(from = new Date(session.starts_at))"></span>
					<span :set="(to = new Date(session.ends_at))"></span>
					<span :set="(duration = ((to - from)/1000/60/60).toFixed(1))"></span>
					<span :set="(isLive = check >= from && check <= to)"></span>
					<span :set="(isLiveWithMargins = check >= moment(from).subtract(30, 'm').toDate() && check <= moment(to).add(15, 'm').toDate())"></span>
					<div class="session-info session-placeholder">&nbsp;</div>
					<div class="session-info-s">
						<div class="session-info-group">
							<div class="session-info session-name text-left">{{ session.name }}</div>
							<div class="session-info session-time text-left">
								<div>
									{{ from.toLocaleDateString() }}
									{{ from.toLocaleTimeString([], {timeStyle: 'short'}) }}&nbsp;
								</div>
								<div>Duration: {{duration}}h</div>
							</div>
						</div>
						<div class="session-info session-description text-left">
							{{ truncate(stripHTML(session.description.body), 89) }}&nbsp;
						</div>
					</div>
					<div class="session-info session-onair text-left">{{ isLive ? "ON AIR" : "" }}</div>
					<div class="session-links-container">
						<div class="session-info session-links">
							<router-link v-if="(isLiveWithMargins)" class="session-partecipate" color="primary"
								:to="`/play/${session.id}?controller=events`" tag="button">
								PARTECIPA
							</router-link>
						</div>
					</div>
					<!-- <ion-router-link v-else class="session-partecipate" color="primary" href="/home">&nbsp;</ion-router-link> -->
				</div>
			</div>
			<!-- </div> -->
		</div>
	</div>
</template>

<style scoped>
	/* AGENDA START */
	.session-placeholder {
		width: 2vw;
	}

	.event-date,
	.session-name {
		width: 14vw;
	}

	.event-time,
	.session-time {
		width: 14vw;
	}

	.event-description,
	.session-description {
		width: 40vw;
	}

	.event-live-now,
	.session-onair {
		width: 15vw;
	}

	/* .event-placeholder,
	.session-links {
	} */

	.session-info {
		padding-right: 4px;
	}

	.session-time {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}

	.agenda-title {
		margin-right: .3em;
		padding-right: .3em;
		border-right: 2px solid white;
	}

	.text-left {
		text-align: left !important;
	}

	.session-partecipate {
		background-color: white;
		border-radius: 1em;
		display: block;
		text-decoration: none;
		text-align: center;
	}

	.top-content>ion-item:nth-child(1)>ion-item:nth-child(1)>ion-icon:nth-child(1) {
		padding-right: .2em;
		margin-right: .2em;
		border-right: 2px solid white;
	}

	.top-content-col {
		display: block;
		flex-direction: row;
		flex-wrap: wrap;
		overflow: hidden auto;
		width: 100%;
		/* height: 100%; */
		min-height: 20vw;
		text-align: center;
	}

	.agenda-filters {
		border-right: 2px solid white;
	}

	.agenda-filters:last-of-type {
		border-right: none;
	}

	.session-info-s {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	.events-datetime, .events-datetime-description {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	.event-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		border-bottom: 1px solid var(--ion-color-primary);
		padding-bottom: 8px;
		color: var(--ion-color-primary);
		font-size: max(1vw, 12px);
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.sessions-container {
		position: relative;

	}

	.session-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		background-color: var(--ion-color-tertiary);
		color: white;
		border-bottom: 1px solid white;
		font-size: max(.9vw, 12px);
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.session-links-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	.session-info-group {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	.event-chevron {
		text-align: center !important;
	}

	.event-chevron img {
		height: 1vw;
	}

	.event-chevron,
	.event-date,
	.event-time,
	.session-placeholder,
	.session-name,
	.session-time {
		width: 10vw;
		font-weight: bold;
	}

	.event-description,
	.session-description {
		width: 40vw;
		text-align: left;
	}

	.event-description * {
		color: black !important;
	}

	.event-live-now,
	.event-placeholder {
		width: 10vw;
		font-weight: bold;
	}

	.session-onair,
	.session-partecipate {
		width: 10vw;
		font-weight: bold;
	}

	.event-live-now {
		color: var(--ion-color-success) !important;
	}

	.session-onair {
		color: red !important;
	}

	.header-icon {
		height: 10vw;
	}

	@media screen and (max-width: 420px) {
	}

	@media screen and (max-width: 920px) {
		.events-datetime-description {
			flex-direction: column;
			width: 90vw;
		}

		.events-datetime-description div {
			width: 90vw;
		}

		.session-header {
			flex-direction: column;
			align-items: center;
		}

		.session-name {
			width: 50vw;
		}

		.session-time {
			flex-direction: row;
			width: 50vw;
		}

		.session-links-container {
			margin-top: 1em;
		}

		.session-onair {
			width: 100vw;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.session-partecipate {
			width: 132px;
		}
		.session-info-s {
			flex-direction: column;
		}

		.session-info-s div {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.session-info-group {
			flex-direction: column;
		}

	}

	/* AGENDA END */
</style>

<script>
	import {
		calendarSharp,
		chevronForwardOutline
	} from "ionicons/icons";

	import {
		IonIcon,
		// IonButtons,
		// IonButton,
		IonItem,
		// IonListHeader,
		// IonHeader,
		// IonList
	} from "@ionic/vue";

	import {
		defineComponent
	} from "vue";


	/* Time js */
	import * as moment from 'moment-timezone';

	import {
		truncate,
		stripHTML,
		checkUserSession
	} from "../plugins/common.js";

	export default defineComponent({
		components: {
			IonIcon,
			// IonButtons,
			// IonButton,
			IonItem,
			// IonListHeader,
			// IonHeader,
			// IonList
		},
		data() {
			return {
				calendarSharp,
				chevronForwardOutline,
				events: null,
				updaterEvents: null,
				intervalTick: 60000,
			};
		},
		watch: {
			$route() {
				//if (this.updaterEvents) this.stopUpdateEvents();
				//else if (this.$route.name == "Home") this.startUpdateEvents();
				if (this.updaterEvents) this.stopUpdateEvents();
				else if (this.$route.name == "Home") this.startUpdateEvents();
			}
		},
		created() {
			this.getEvents();
			this.startUpdateEvents();
		},
		methods: {
			moment,
			truncate,
			stripHTML,
			checkUserSession,
			getEvents(updateToken = true) {
				//this.$store
					//.dispatch("tables/fetch", {
					//	model: "event_kinds?q[current]=true",
					//	updateToken: updateToken
				let currSearch = {
					q: {
						current: true,
						s: "starts_at",
					},
				};
				this.$store
					.dispatch("tables/jsonSearch", {
						model: "event_kinds",
						data: currSearch,
						updateToken: updateToken
					}).then((data) => {
						this.events = data;
					}).catch((error) => {
						this.checkUserSession(error);
					})
			},
			startUpdateEvents() {
				this.updaterEvents = setInterval(
					this.getEvents,
					this.intervalTick,
					false
				);
			},
			stopUpdateEvents() {
				clearInterval(this.updaterEvents);
				this.updaterEvents = null
			},
			toggleSessions(element) {
				var eventId = element.target.dataset.sessionFor;
				var x = document.getElementById(`sessions-for-${eventId}`);
				if (x.style.display === "none") {
					x.style.display = "block";
					document.querySelector(`#chevron-${eventId}`).style.transform =
						"rotate(90deg)";
				} else {
					x.style.display = "none";
					document.querySelector(`#chevron-${eventId}`).style.transform =
						"rotate(0deg)";
				}
			},
		},
	});
</script>