<template>
	<div class="block live players">
		<div class="video-wrapper">
			<video :id="playerName" class="main op-player__media" autoplay playsInline autoPictureInPicture
				preload="metadata" :poster="require('../assets/thumbnail-video.jpg')">
				<!-- <source :src="`${currentURL}/assets/placeholder.m3u8`" type="application/x-mpegURL" /> -->
			</video>
		</div>
		<div class="video-wrapper hidden">
			<video :id="`${playerName}2`" class="main op-player__media" autoplay playsInline autoPictureInPicture
				preload="metadata" :poster="require('../assets/thumbnail-video.jpg')">
				<!-- <source :src="`${currentURL}/assets/placeholder.m3u8`" type="application/x-mpegURL" /> -->
			</video>
		</div>
	</div>
</template>

<style scoped>
	.player-menu-item {
		height: 20px;
	}

	.live {
		background: black;
		position: relative;
		height: 100%;
	}

	.video-wrapper {
		width: 100%;
		height: 100%;
	}

	.video {
		width: 100%;
		background-color: black;
	}

	.hidden {
		display: none !important;
	}
</style>

<script>
	import {
		defineComponent
	} from "vue";

	import OpenPlayerJS from "openplayerjs";
	import "openplayerjs/dist/openplayer.css";

	import axios from "axios";

	import mux from '../../node_modules/mux-embed';

	import {
		muxMonitorEnvKey,
		feName
	} from '../plugins/variables.js'

	export default defineComponent({
		emits: [
			"is-loading"
		],
		data() {
			return {
				currentTime: null,
				currentURL: null,
				// $ to not have this varibale trnasformed into an observable reactive Proxy
				$player: null,
				$player2: null,
				urlIsLoading: null,
				languages: [],
				$keepAliveTimer: null
			};
		},
		props: {
			playerName: String,
			removedControls: Array
		},
		created() {
			this.currentURL = window.location.href.replace(this.$route.fullPath, '')
		},
		methods: {
			logicForPlayerSwitch(url, backgroundPlayer, foregroundPlayer) {
				backgroundPlayer.src = {
					src: url,
					type: "application/x-mpegURL"
				}
				backgroundPlayer.load();
				backgroundPlayer.getElement().addEventListener('hlsManifestLoaded', () => {
					const time = foregroundPlayer.getElement().currentTime;
					backgroundPlayer.getElement().currentTime = time;
					backgroundPlayer.play();
					this.$emit('is-loading', false)
				});
				backgroundPlayer.getElement().addEventListener('playing', () => {
					backgroundPlayer.getContainer().closest('.video-wrapper').classList.remove('hidden');
					foregroundPlayer.getContainer().closest('.video-wrapper').classList.add('hidden');
					foregroundPlayer.pause();
					this.$emit('is-loading', false)
				});
			},
			buildSubItemelement(lang, pId) {
				return {
					id: `submenu-${pId}-${lang["id"]}`,
					label: lang["language_name"],
					title: `Click here to switch to ${lang["language_name"]} language`,
					// icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/03/Flag_of_Italy.svg/200px-Flag_of_Italy.svg.png',
					click: () => {
						// the player it visible or (else) the player2 is visible
						// this.playIt(lang["playback_url"])
						this.playIt(lang["playback_url"])
						// TODO: Remove below code when done with testing
						// this.$emit('is-loading', true)
						// if (this.$player2.getContainer().closest('.video-wrapper').classList.contains("hidden")) this.logicForPlayerSwitch(lang["playback_url"], this.$player2, this.$player)
						// else this.logicForPlayerSwitch(lang["playback_url"], this.$player, this.$player2)
					}
				}
			},
			playIt(src) {
				// Manage playing in the same way as the language switch, 
				// just to be sure to send the src to the visible player
				this.$emit('is-loading', true)
				if (this.$player2.getContainer().closest('.video-wrapper').classList.contains("hidden")) this
					.logicForPlayerSwitch(src, this.$player2, this.$player)
				else this.logicForPlayerSwitch(src, this.$player, this.$player2)
				// TODO: Remove below code when done with testing
				// this.$player.src = {
				// 	src,
				// 	type: "application/x-mpegURL"
				// };
				// // player.play()
				// this.$player.load().then(() => {
				// 	this.$player.play();
				// 	this.$emit('is-loading', false)
				// });
			},
			createPlayer(src, langs) {
				console.log("createPlayer Langs:", langs)
				// If langs, allora devo gestire le lingue
				if (document.getElementById('languages-selector-player')) this.$player.removeControl(
					'languages-selector-player');
				if (document.getElementById('languages-selector-player2')) this.$player2.removeControl(
					'languages-selector-player2');
				// Creating the object for subitems
				// Each lang is %like: 
				/* {
				id: 12,
				name: "Italian",
				playback_url: "https://..."
				} */
				// adding languages
				if (langs && langs.length) {
					console.log("createPlayer There are langs")

					var subItemsArray = []
					var subItemsArrayOther = []

					langs.forEach(lang => {
						subItemsArray.push(this.buildSubItemelement(lang, "player"))
						subItemsArrayOther.push(this.buildSubItemelement(lang, "player2"))
					});

					this.$player.addControl({
						icon: `<img class="player-menu-item" src="${require("@/assets/languages.png")}" style="height: 16px"/>`,
						id: 'languages-selector-player',
						title: 'Languages',
						position: 'right',
						showInAds: false,
						subitems: subItemsArray
					})

					this.$player2.addControl({
						icon: `<img class="player-menu-item" src="${require("@/assets/languages.png")}" style="height: 16px"/>`,
						id: 'languages-selector-player2',
						title: 'Languages',
						position: 'right',
						showInAds: false,
						subitems: subItemsArrayOther
					})
				}

				// Seding the first play() command on the this.$player
				this.playIt(src);
			},
			loadURL(url, ls) {
				console.log("Langs in loadURL", ls)
				console.log("Sending the get for " + url)
				axios.get(url).then(() => {
					// The operation is ok, thus resets the current get operation (do not repeat)
					// console.log("URL LOADED")
					clearInterval(this.urlIsLoading)
					this.createPlayer(url, ls)
				}).catch((e) => {
					console.log("Live or On Demand URL still not available", e)
				})
			},
			sendToPlayer(url, ls) {
				console.log("URL in sendToplayer", url)
				console.log("Langs in sendToplayer", ls)
				// Making the parent know it's starting to play
				this.$emit('is-loading', true)
				// Clicking another play button, resets the previous get operation
				clearInterval(this.urlIsLoading)
				// At any new video src change, start a new keepalive, removing the previous one, if any
				this.stopKeepAlive()
				this.startKeepAlive()
				// $(".fa-spinner").removeClass("paused").addClass("rotate")
				// Select the url link anchor text
				this.urlIsLoading = setInterval(this.loadURL, 3000, url, ls);
			},
			muxSDKInit(playerId) {
				if (typeof mux !== 'undefined' && muxMonitorEnvKey) {
					mux.monitor(`#${playerId}`, {
						debug: false,
						data: {
							env_key: muxMonitorEnvKey, // required
							// Metadata fields
							player_name: `${feName}: ${playerId}`, // any arbitrary string you want to use to identify this player
							player_init_time: window.muxPlayerInitTime // ex: 1451606400000
							// ...
						}
					});
				}
			},
			initPlayer() {
				// Openplayer JS Options
				const options = {
					mode: 'fit',
					showLoaderOnInit: false,
					pauseOthers: false,
					detachMenus: true,
					hls: {
						limitRenditionByPlayerDimensions: false,
						smoothQualityChange: true,
						overrideNative: true
					},
				}

				try {
					this.$player.destroy()
					this.$player2.destroy()
				} catch (e) {
					console.log("No player to be destroyed", e)
				}
				// Mux.com data statistics
				this.muxSDKInit(this.playerName)
				this.muxSDKInit(`${this.playerName}2`)

				// console.log("Initializing player", this.playerName)
				this.$player = new OpenPlayerJS(this.playerName, options);
				this.$player.init();
				this.$player2 = new OpenPlayerJS(`${this.playerName}2`, options);
				this.$player2.init();

				if (this.removedControls && this.removedControls.length) {
					this.removedControls.forEach((val) => {
						try {
							this.$player.removeControl(val)
							this.$player2.removeControl(val)
						} catch (e) {
							console.log("IINIT: No player available for removing control", val, e)
						}
					})
				}
			},
			destroyPlayer() {
				clearInterval(this.urlIsLoading)
				this.stopKeepAlive()
				// console.log("Destroying player", this.playerName)
				this.$player.destroy()
				this.$player2.destroy()
			},
			startKeepAlive() {
				this.$keepAliveTimer = setInterval(() => {
						this.$store.dispatch("tables/fetch", {
							model: "info/heartbeat"
						}).catch((error) => {
							this.checkUserSession(error);
						})
					},
					600000
				);
			},
			stopKeepAlive() {
				clearInterval(this.$keepAliveTimer);
				this.$keepAliveTimer = null
			},
		},
	});
</script>